<template>
  <ion-page>
    <ion-header>
      <BaseToolbar :page-title="$t('views.contact-us')" />
    </ion-header>
    <ion-content>
      <BaseContent class="contact-us">
        <p v-text="$t('contact-us.message')" />

        <ion-button
          color="primary"
          expand="block"
          href="mailto:contact@tokenvote.co"
        >
          <ion-icon slot="start" :icon="mailOutline" />
          contact@tokenvote.co
        </ion-button>
      </BaseContent>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import { IonPage, IonContent, IonHeader, IonButton, IonIcon } from '@ionic/vue';
import { mailOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'ContactUs',
  components: {
    BaseToolbar,
    BaseContent,
    IonPage,
    IonContent,
    IonHeader,
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      mailOutline,
    };
  },
});
</script>

<style lang="scss" scoped>
.contact-us {
}
</style>
