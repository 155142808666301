
import { defineComponent } from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import { IonPage, IonContent, IonHeader, IonButton, IonIcon } from '@ionic/vue';
import { mailOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'ContactUs',
  components: {
    BaseToolbar,
    BaseContent,
    IonPage,
    IonContent,
    IonHeader,
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      mailOutline,
    };
  },
});
